// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  CampaignListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './FundingRoundListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import FundingRoundCard from '../FundingRoundCard/FundingRoundCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers
import { useInfiniteScroll } from '@/hooks';

interface IFundingRoundList {
  data: {
    items: IFundingRound[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  getNextFundingRounds: () => void;
}

const FundingRoundList = ({
  data,
  isLoading,
  getNextFundingRounds,
}: IFundingRoundList) => {
  const { t } = useTranslation(['fundingRound']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedFundingList = useMemo(() => {
    return data?.items?.map((dataItem) => {
      return <FundingRoundCard data={dataItem} key={dataItem?.id} />;
    });
  }, [data?.items]);

  useInfiniteScroll({
    callback: getNextFundingRounds,
    ref: loaderRef,
    isLoading,
    hasMore: data?.hasMore,
  });

  return (
    <CampaignListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('funding_list_project_title')}</StyledTitles>
          <StyledTitles>{t('funding_list_tge_date')}</StyledTitles>
          <StyledTitles>{t('funding_list_funding_reached')}</StyledTitles>
          <StyledTitles>{t('funding_list_funding_target')}</StyledTitles>
          <StyledTitles>{t('funding_list_phase')}</StyledTitles>
          <StyledTitles>{t('funding_list_status')}</StyledTitles>
          <StyledTitles>{t('funding_list_details')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedFundingList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </CampaignListWrapper>
  );
};

export default FundingRoundList;
