import { useTranslation } from 'react-i18next';
import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { TextLength } from '@/constants';
import { FundingContractFieldsNames } from '../types';
import { TFormValidatorRule } from '@/types';

const useGetContractFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });

  const textDescriptionLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.large,
  });

  const titleFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.titleMedium,
  });

  return {
    [FundingContractFieldsNames.TITLE]: [required, titleFieldLength],
    [FundingContractFieldsNames.DESCRIPTION]: [required, textDescriptionLength],
    [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_VALUE]: [
      required,
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_VALUE]: [
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_VALUE]: [
      required,
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.GOVERNING_LAW_VALUE]: [
      required,
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.DISPUTE_RESOLUTION_VALUE]: [
      required,
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.RETENTION_OF_RIGHTS_VALUE]: [
      required,
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.NOTICES_VALUE]: [
      required,
      textDescriptionLength,
    ],
  } as Record<FundingContractFieldsNames, TFormValidatorRule[]>;
};

export default useGetContractFormValidationRules;
