import { TFunction } from 'react-i18next';
import {
  TLinkedStep,
  FundingSteps,
  FundingTabs,
  IFundingRoundFields,
  FundingContractFieldsNames,
} from '../types';
import {
  FundingRoundPhases,
  FundingRoundStatuses,
} from '@/models/fundingRound.model';

export const defaultAdditionalPercentage = 5;

export const phaseOptions = [
  {
    value: FundingRoundPhases.PRE_SEED,
    label: 'Pre Seed',
  },
  {
    value: FundingRoundPhases.SEED,
    label: 'Seed',
  },
  {
    value: FundingRoundPhases.PRIVATE,
    label: 'Private',
  },
];

export const fundingRoundSteps: Record<FundingSteps, TLinkedStep> = {
  [FundingSteps.ELEVATOR_PITCH]: {
    key: FundingSteps.ELEVATOR_PITCH,
    prev: null,
    next: FundingSteps.PRESENTATION,
  },
  [FundingSteps.PRESENTATION]: {
    key: FundingSteps.PRESENTATION,
    prev: FundingSteps.ELEVATOR_PITCH,
    next: FundingSteps.TOKENOMICS,
  },
  [FundingSteps.TOKENOMICS]: {
    key: FundingSteps.TOKENOMICS,
    prev: FundingSteps.PRESENTATION,
    next: FundingSteps.LEGAL_REQUIREMENTS,
  },
  [FundingSteps.LEGAL_REQUIREMENTS]: {
    key: FundingSteps.LEGAL_REQUIREMENTS,
    prev: FundingSteps.TOKENOMICS,
    next: FundingSteps.OVERVIEW,
  },
  [FundingSteps.OVERVIEW]: {
    key: FundingSteps.OVERVIEW,
    prev: FundingSteps.LEGAL_REQUIREMENTS,
    next: null,
  },
  [FundingSteps.PARTICIPATION]: {
    key: FundingSteps.PARTICIPATION,
    prev: null,
    next: null,
  },
};

export const fundingTabsConfig = [
  {
    label: 'elevator-pitch',
    key: FundingSteps.ELEVATOR_PITCH.toString(),
    name: FundingTabs.ELEVATOR_PITCH,
  },
  {
    label: 'presentation',
    key: FundingSteps.PRESENTATION.toString(),
    name: FundingTabs.PRESENTATION,
  },
  {
    label: 'tokenomics',
    key: FundingSteps.TOKENOMICS.toString(),
    name: FundingTabs.TOKENOMICS,
  },
  {
    label: 'legal-requirements',
    key: FundingSteps.LEGAL_REQUIREMENTS.toString(),
    name: FundingTabs.LEGAL_REQUIREMENTS,
  },
  {
    label: 'participation',
    key: FundingSteps.PARTICIPATION.toString(),
    name: FundingTabs.PARTICIPATION,
  },
  {
    label: 'overview',
    key: FundingSteps.OVERVIEW.toString(),
    name: FundingTabs.OVERVIEW,
  },
];

export const getFundingSteps = (t: TFunction) => [
  {
    title: t('funding_round_steps_elevator'),
  },
  {
    title: t('funding_round_steps_presentation'),
  },
  {
    title: t('funding_round_steps_tokenomics'),
  },
  {
    title: t('funding_round_steps_legal_requirements'),
  },
  {
    title: t('funding_round_steps_overview'),
    disabled: true,
  },
];

export const statusesConfigs = [
  {
    value: FundingRoundStatuses.PENDING,
    label: 'Pending',
    statuses: [FundingRoundStatuses.PAUSED],
  },
  {
    value: FundingRoundStatuses.WAITING_FOR_APPROVAL,
    label: 'Waiting for Approval',
    statuses: [FundingRoundStatuses.PENDING, FundingRoundStatuses.PAUSED],
  },
  {
    value: FundingRoundStatuses.ACTIVE,
    label: 'Active',
    statuses: [FundingRoundStatuses.PAUSED],
  },
  {
    value: FundingRoundStatuses.PAUSED,
    label: 'Paused',
    statuses: [
      FundingRoundStatuses.PENDING,
      FundingRoundStatuses.WAITING_FOR_APPROVAL,
      FundingRoundStatuses.ACTIVE,
    ],
  },
  {
    value: FundingRoundStatuses.CANCELED,
    label: 'Canceled',
    statuses: [FundingRoundStatuses.PAUSED],
  },
  {
    value: FundingRoundStatuses.ARCHIVED,
    label: 'Archived',
    statuses: [FundingRoundStatuses.COMPLETED, FundingRoundStatuses.CANCELED],
  },
];

export const milestoneColumnsConfig = [
  {
    title: 'funding_target_milestone_title',
    dataIndex: 'title',
  },
  {
    title: 'funding_target_milestone_list',
    dataIndex: 'items',
  },
  {
    title: 'funding_target_milestone_approval',
    dataIndex: 'requirements',
  },
  {
    title: 'funding_target_milestone_status',
    dataIndex: 'icon',
  },
];

export const columnsConfig = [
  {
    title: '',
    dataIndex: 'title',
  },
  {
    title: '',
    dataIndex: 'items',
  },
];

export const cryptoCurrenciesList = [
  {
    label: 'USDT',
    value: 'USDT',
  },
  {
    label: 'USDC',
    value: 'USDC',
  },
];

export const CREATED_FUNDING_ROUND_STATUSES = new Set([
  FundingRoundStatuses.ACTIVE,
  FundingRoundStatuses.PAUSED,
  FundingRoundStatuses.CANCELED,
  FundingRoundStatuses.ARCHIVED,
  FundingRoundStatuses.FINISHED,
  FundingRoundStatuses.COMPLETED,
]);

export const DISABLED_FUNDING_ROUND_STATUSES = new Set([
  FundingRoundStatuses.CANCELED,
  FundingRoundStatuses.ARCHIVED,
  FundingRoundStatuses.FINISHED,
  FundingRoundStatuses.COMPLETED,
]);

export const ENABLED_FUNDING_ROUND_TOKENOMICS_STATUSES = new Set([
  FundingRoundStatuses.ACTIVE,
  FundingRoundStatuses.PAUSED,
  FundingRoundStatuses.PENDING,
  FundingRoundStatuses.WAITING_FOR_APPROVAL,
  FundingRoundStatuses.PROJECT_PAUSED,
]);

export const ELEVATOR_PITCH_STEP_FIELDS = new Set([
  IFundingRoundFields.TITLE,
  IFundingRoundFields.ELEVATOR_PITCH,
  IFundingRoundFields.TGE_DATE,
  IFundingRoundFields.START_DATE,
  IFundingRoundFields.END_DATE,
  IFundingRoundFields.WALLET_NETWORK,
  IFundingRoundFields.WALLET_ADDRESS,
  IFundingRoundFields.WALLET_CURRENCY,
]);

export const PRESENTATION_STEP_FIELDS = new Set([
  IFundingRoundFields.TOKENOMICS,
  IFundingRoundFields.PITCH_DECK,
  IFundingRoundFields.BANNER_IMAGE,
  IFundingRoundFields.TOKEN_IMAGE,
]);

export const TOKENOMICS_STEP_FIELDS = new Set([
  IFundingRoundFields.TOKEN_NAME,
  IFundingRoundFields.PHASE,
  IFundingRoundFields.TOKEN_SUPPLY,
  IFundingRoundFields.TOTAL_TOKENS_AMOUNT_FOR_SELECTED_PHASE,
  IFundingRoundFields.HARD_CAP,
  IFundingRoundFields.TOKEN_AMOUNT_TO_BE_MANAGED,
  IFundingRoundFields.PRICE_PER_TOKEN,
  IFundingRoundFields.TOKEN_ALLOCATION_PERCENT,
  IFundingRoundFields.TGE_UNLOCK,
  IFundingRoundFields.VESTING_PERIOD,
  IFundingRoundFields.TOKEN_SALE_SUMMARY,
]);

export const LEGAL_REQUIREMENTS_STEP_FIELDS = new Set([
  FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_VALUE,
  FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_VALUE,
  FundingContractFieldsNames.INTELLECTUAL_PROPERTY_VALUE,
  FundingContractFieldsNames.GOVERNING_LAW_VALUE,
  FundingContractFieldsNames.DISPUTE_RESOLUTION_VALUE,
  FundingContractFieldsNames.RETENTION_OF_RIGHTS_VALUE,
  FundingContractFieldsNames.NOTICES_VALUE,
]);
