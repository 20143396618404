import { IFundingContract } from '@/models/fundingRound.model';
import { FundingContractFieldsNames, TFundingContractValues } from '../types';

export const contractInfoAdapter = (
  data: IFundingContract,
): TFundingContractValues => ({
  [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_VALUE]:
    data.additionalDefinitions?.value,
  [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_VALUE]:
    data.excludedJurisdictions?.value,
  [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_VALUE]:
    data.intellectualProperty?.value,
  [FundingContractFieldsNames.GOVERNING_LAW_VALUE]: data.governingLaw?.value,
  [FundingContractFieldsNames.DISPUTE_RESOLUTION_VALUE]:
    data.disputeResolution?.value,
  [FundingContractFieldsNames.RETENTION_OF_RIGHTS_VALUE]:
    data.retentionOfRights?.value,
  [FundingContractFieldsNames.NOTICES_VALUE]: data.notices?.value,

  [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_CONFIRMATION]:
    !!data.additionalDefinitions?.isConfirmed,
  [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_CONFIRMATION]:
    !!data.excludedJurisdictions?.isConfirmed,
  [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_CONFIRMATION]:
    !!data.intellectualProperty?.isConfirmed,
  [FundingContractFieldsNames.GOVERNING_LAW_CONFIRMATION]:
    !!data.governingLaw?.isConfirmed,
  [FundingContractFieldsNames.DISPUTE_RESOLUTION_CONFIRMATION]:
    !!data.disputeResolution?.isConfirmed,
  [FundingContractFieldsNames.RETENTION_OF_RIGHTS_CONFIRMATION]:
    !!data.retentionOfRights?.isConfirmed,
  [FundingContractFieldsNames.NOTICES_CONFIRMATION]:
    !!data.notices?.isConfirmed,

  [FundingContractFieldsNames.ITEMS]: data.items,
});
