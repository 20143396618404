import {
  IFundingContractDTO,
  IFundingContractItem,
  IFundingContractRequiredItem,
} from '@/models/fundingRound.model';
import { FundingContractFieldsNames, TFundingContractValues } from '../types';

class FundingContractDTO implements IFundingContractDTO {
  additionalDefinitions?: IFundingContractRequiredItem;
  excludedJurisdictions?: IFundingContractRequiredItem;
  intellectualProperty?: IFundingContractRequiredItem;
  governingLaw?: IFundingContractRequiredItem;
  disputeResolution?: IFundingContractRequiredItem;
  retentionOfRights?: IFundingContractRequiredItem;
  notices?: IFundingContractRequiredItem;
  items?: IFundingContractItem[];

  constructor(values: TFundingContractValues) {
    const {
      [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_VALUE]:
        additionalDefinitionsValue,
      [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS_CONFIRMATION]:
        additionalDefinitionsConfirmation,
      [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_VALUE]:
        excludedJurisdictionsValue,
      [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS_CONFIRMATION]:
        excludedJurisdictionsConfirmation,
      [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_VALUE]:
        intellectualPropertyValue,
      [FundingContractFieldsNames.INTELLECTUAL_PROPERTY_CONFIRMATION]:
        intellectualPropertyConfirmation,
      [FundingContractFieldsNames.GOVERNING_LAW_VALUE]: governingLawValue,
      [FundingContractFieldsNames.GOVERNING_LAW_CONFIRMATION]:
        governingLawConfirmation,
      [FundingContractFieldsNames.DISPUTE_RESOLUTION_VALUE]:
        disputeResolutionValue,
      [FundingContractFieldsNames.DISPUTE_RESOLUTION_CONFIRMATION]:
        disputeResolutionConfirmation,
      [FundingContractFieldsNames.RETENTION_OF_RIGHTS_VALUE]:
        retentionOfRightsValue,
      [FundingContractFieldsNames.RETENTION_OF_RIGHTS_CONFIRMATION]:
        retentionOfRightsConfirmation,
      [FundingContractFieldsNames.NOTICES_VALUE]: noticesValue,
      [FundingContractFieldsNames.NOTICES_CONFIRMATION]: noticesConfirmation,
    } = values;

    this.items = values[FundingContractFieldsNames.ITEMS]?.filter(
      (item) => item.description,
    );

    this.excludedJurisdictions = {
      value: excludedJurisdictionsValue || '',
      isConfirmed: !!excludedJurisdictionsConfirmation,
    };

    if (additionalDefinitionsValue) {
      this.additionalDefinitions = {
        value: additionalDefinitionsValue,
        isConfirmed: !!additionalDefinitionsConfirmation,
      };
    }

    if (intellectualPropertyValue) {
      this.intellectualProperty = {
        value: intellectualPropertyValue,
        isConfirmed: !!intellectualPropertyConfirmation,
      };
    }

    if (governingLawValue) {
      this.governingLaw = {
        value: governingLawValue,
        isConfirmed: !!governingLawConfirmation,
      };
    }

    if (disputeResolutionValue) {
      this.disputeResolution = {
        value: disputeResolutionValue,
        isConfirmed: !!disputeResolutionConfirmation,
      };
    }

    if (retentionOfRightsValue) {
      this.retentionOfRights = {
        value: retentionOfRightsValue,
        isConfirmed: !!retentionOfRightsConfirmation,
      };
    }

    if (noticesValue) {
      this.notices = {
        value: noticesValue,
        isConfirmed: !!noticesConfirmation,
      };
    }
  }
}

export default FundingContractDTO;
