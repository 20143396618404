// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfo,
  StyledSubTitle,
  StyledText,
  StyledDetails,
  Wrapper,
  StyledRow,
  StyledDownloadButton,
  StyledWalletWrapper,
} from './GeneralInfoDetailsBlockStyles';
import { Link } from '@/ui';
import { WalletBlock } from '@/components';

// Modules
import KYCStatus from '@/modules/KolsList/components/GeneralInfo/KYCStatus';

// Models
import { IKOL } from '@/models/kolsList.model';
import { VerificationTypes } from '@/models/sharedProfile.model';

// Helpers
import {
  renderUsername,
  getDateString,
  getUserLink,
  getCountryLabel,
  buildSynapsLink,
} from '@/utils';
import { isDev } from 'src/config/env';
import { TextFileIcon } from '@/icons';

interface IGeneralInfo {
  kolDetails: IKOL | null;
}

const GeneralInfoDetailsBlock = ({ kolDetails }: IGeneralInfo) => {
  const { t } = useTranslation(['kolsList']);
  const { firstName, lastName, formAddress } = kolDetails ?? {};
  const goToInvitedByDetails = getUserLink(
    kolDetails?.invitedBy?.role ?? '',
    kolDetails?.invitedBy?.id ?? '',
  );

  const synapsSessionId = kolDetails?.KYC?.sessionId;
  const synapsLink = synapsSessionId
    ? buildSynapsLink(
        synapsSessionId,
        isDev ? VerificationTypes.KYC_DEV : VerificationTypes.KYC,
      )
    : '#';

  const synapsNewLink = synapsSessionId
    ? buildSynapsLink(
        synapsSessionId,
        isDev ? VerificationTypes.KYC_LITE_DEV : VerificationTypes.KYC_LITE,
      )
    : '#';

  return (
    <Wrapper>
      <StyledRow>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>{t('kols_general_info_name_title')}</StyledSubTitle>
            <StyledText>
              {renderUsername({
                firstName,
                lastName,
                formAddress,
              })}
            </StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_alias_title')}
            </StyledSubTitle>
            <StyledText>{kolDetails?.alias}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>{t('kols_general_info_ID_proof')}</StyledSubTitle>
            {kolDetails?.documentIdProof && (
              <StyledText>
                <StyledDownloadButton
                  href={kolDetails?.documentIdProof}
                  target={'_blank'}
                >
                  <TextFileIcon />
                </StyledDownloadButton>
              </StyledText>
            )}
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_document_number')}
            </StyledSubTitle>
            {kolDetails?.KYC?.documentNumber && (
              <StyledText>{kolDetails?.KYC?.documentNumber}</StyledText>
            )}
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_nationality')}
            </StyledSubTitle>
            {kolDetails?.KYC?.nationality && (
              <StyledText>{kolDetails?.KYC?.nationality}</StyledText>
            )}
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>{t('kols_general_info_KYC_title')}</StyledSubTitle>
            <KYCStatus status={kolDetails?.KYC?.state} />
          </StyledDetails>
        </StyledInfo>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_country_title')}
            </StyledSubTitle>
            <StyledText>
              {getCountryLabel(kolDetails?.country ?? '')}
            </StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>{t('kols_general_info_city_title')}</StyledSubTitle>
            <StyledText>{kolDetails?.city}</StyledText>
          </StyledDetails>
        </StyledInfo>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_contact_source')}
            </StyledSubTitle>
            <StyledText>{kolDetails?.mainContact?.source}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>{t('kols_general_info_contact')}</StyledSubTitle>
            <StyledText>{kolDetails?.mainContact?.contact}</StyledText>
          </StyledDetails>
          {kolDetails?.invitedBy && (
            <StyledDetails>
              <StyledSubTitle>
                {t('kols_general_info_invited_by')}
              </StyledSubTitle>
              <Link
                to={goToInvitedByDetails ?? ''}
                target="_blank"
              >{`${kolDetails?.invitedBy?.firstName} ${kolDetails?.invitedBy?.lastName}`}</Link>
            </StyledDetails>
          )}
          <StyledDetails>
            <StyledSubTitle>{t('kols_list_synaps_title')}</StyledSubTitle>
            {synapsSessionId ? (
              <Link target="_blank" to={synapsLink}>
                {t('kols_list_go_synaps_title')}
              </Link>
            ) : (
              '-'
            )}
            {synapsSessionId ? (
              <Link target="_blank" to={synapsNewLink}>
                {t('kols_list_go_synaps_lite_title')}
              </Link>
            ) : (
              '-'
            )}
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_email_title')}
            </StyledSubTitle>
            <StyledText>{kolDetails?.email}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('kols_general_info_registration_date_title')}
            </StyledSubTitle>
            {kolDetails?.created && (
              <StyledText>{getDateString(kolDetails?.created)}</StyledText>
            )}
          </StyledDetails>
        </StyledInfo>
      </StyledRow>
      <StyledWalletWrapper>
        <WalletBlock
          walletAddress={kolDetails?.walletAddress}
          walletCurrency={kolDetails?.walletCurrency}
          walletNetwork={kolDetails?.walletNetwork}
        />
      </StyledWalletWrapper>
    </Wrapper>
  );
};

export default GeneralInfoDetailsBlock;
