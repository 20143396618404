// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledHeaderWrapper,
  StyledLinksWrap,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import { IRequestDetails } from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, getUserLink } from '@/utils';
import { ROUTES_PATHS } from '@/router';

interface IFundingRoundApplicationRequestDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
}

const FundingRoundApplicationRequestDetails = ({
  request,
  isLoading,
}: IFundingRoundApplicationRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const {
    firstName,
    lastName,
    email,
    mainContact,
    role,
    id,
    created,
    title,
    whitepaper,
  } = request?.requester ?? {};

  const goToRequester = getUserLink(role ?? '', id ?? '');
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t(`requests_profile_${role}_label`)}
        </StyledTitle>
        <StyledLinksWrap>
          <Link
            to={`${ROUTES_PATHS.FUNDING_ROUNDS}/${encodeURIComponent(
              request?.fundingRound?.id ?? '',
            )}`}
            target="_blank"
          >
            <StyledButton type="default">
              {t('verification_request_go_to_funding_round')}
            </StyledButton>
          </Link>
          <Link to={goToRequester ?? ''} target="_blank">
            <StyledButton type="primary">
              {t('verification_request_go_to_project')}
            </StyledButton>
          </Link>
        </StyledLinksWrap>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('funding_round_project_name')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={title} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_registration_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(created ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_email_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={email} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact_source')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.source} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.contact} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      {whitepaper && (
        <BlockWrapper>
          <StyledText>{t('funding_round_whitepaper')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={whitepaper} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
      )}

      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default FundingRoundApplicationRequestDetails;
