// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfo,
  StyledSubTitle,
  StyledText,
  StyledDetails,
  StyledRow,
  Wrapper,
} from './GeneralInfoDetailsBlockStyles';
import { Link } from '@/ui';
import { WalletBlock } from '@/components';

// Modules
import KYCStatus from '@/modules/KolsList/components/GeneralInfo/KYCStatus';

// Models
import { IPartner } from '@/models/partnersList.model';
import { VerificationTypes } from '@/models/sharedProfile.model';

// Helpers
import {
  renderUsername,
  getDateString,
  getUserLink,
  getCountryLabel,
  buildSynapsLink,
} from '@/utils';
import { isDev } from 'src/config/env';

interface IGeneralInfoDetailsBlock {
  partnerDetails: IPartner | null;
}

const GeneralInfoDetailsBlock = ({
  partnerDetails,
}: IGeneralInfoDetailsBlock) => {
  const { t } = useTranslation(['partnersList']);
  const { firstName, lastName, formAddress } = partnerDetails ?? {};
  const goToInvitedByDetails = getUserLink(
    partnerDetails?.invitedBy?.role ?? '',
    partnerDetails?.invitedBy?.id ?? '',
  );

  const synapsSessionId = partnerDetails?.KYC?.sessionId;
  const synapsLink = synapsSessionId
    ? buildSynapsLink(
        synapsSessionId,
        isDev ? VerificationTypes.KYC_DEV : VerificationTypes.KYC,
      )
    : '#';

  const synapsLiteLink = synapsSessionId
    ? buildSynapsLink(
        synapsSessionId,
        isDev ? VerificationTypes.KYC_LITE_DEV : VerificationTypes.KYC_LITE,
      )
    : '#';

  return (
    <Wrapper>
      <StyledRow>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_name_title')}
            </StyledSubTitle>
            <StyledText>
              {renderUsername({
                firstName,
                lastName,
                formAddress,
              })}
            </StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_KYC_title')}
            </StyledSubTitle>
            <KYCStatus status={partnerDetails?.KYC?.state} />
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>{t('hunters_list_synaps_title')}</StyledSubTitle>
            {synapsSessionId ? (
              <Link target="_blank" to={synapsLink}>
                {t('hunters_list_go_synaps_title')}
              </Link>
            ) : (
              '-'
            )}
            {synapsSessionId ? (
              <Link target="_blank" to={synapsLiteLink}>
                {t('hunters_list_go_synaps_lite_title')}
              </Link>
            ) : (
              '-'
            )}
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_registration_date_title')}
            </StyledSubTitle>
            {partnerDetails?.created && (
              <StyledText>{getDateString(partnerDetails?.created)}</StyledText>
            )}
          </StyledDetails>
        </StyledInfo>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_country_title')}
            </StyledSubTitle>
            <StyledText>
              {getCountryLabel(partnerDetails?.country ?? '')}
            </StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_city_title')}
            </StyledSubTitle>
            <StyledText>{partnerDetails?.city}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_address_title')}
            </StyledSubTitle>
            <StyledText>{partnerDetails?.address}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <WalletBlock
              walletAddress={partnerDetails?.walletAddress}
              walletCurrency={partnerDetails?.walletCurrency}
              walletNetwork={partnerDetails?.walletNetwork}
            />
          </StyledDetails>
          {/*<StyledSubTitle>{t('hunters_general_info_zip_code_title')}</StyledSubTitle>*/}
          {/*<StyledText>S41 3PJ Whittington </StyledText>*/}
          {/*<StyledSubTitle>{t('hunters_general_info_land_title')}</StyledSubTitle>*/}
          {/*<StyledText>United Kingdom</StyledText>*/}
        </StyledInfo>
        <StyledInfo>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_contact_source')}
            </StyledSubTitle>
            <StyledText>{partnerDetails?.mainContact?.source}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>{t('hunters_general_info_contact')}</StyledSubTitle>
            <StyledText>{partnerDetails?.mainContact?.contact}</StyledText>
          </StyledDetails>
          <StyledDetails>
            <StyledSubTitle>
              {t('hunters_general_info_email_title')}
            </StyledSubTitle>
            <StyledText>{partnerDetails?.email}</StyledText>
          </StyledDetails>
          {partnerDetails?.invitedBy && (
            <StyledDetails>
              <StyledSubTitle>
                {t('hunters_general_info_invited_by')}
              </StyledSubTitle>
              <Link
                to={goToInvitedByDetails ?? ''}
                target="_blank"
              >{`${partnerDetails?.invitedBy?.firstName} ${partnerDetails?.invitedBy?.lastName}`}</Link>
            </StyledDetails>
          )}
        </StyledInfo>
      </StyledRow>
    </Wrapper>
  );
};

export default GeneralInfoDetailsBlock;
