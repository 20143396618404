import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Checkbox, TextArea } from '@/ui';
import { Form } from '@/components';

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledBlockWrapper = styled(BlockLayout)<{ $column?: boolean }>`
  &&& {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    gap: 1rem;
    align-items: flex-start;
    flex-direction: column;

    .ant-input {
      min-width: 0;
      width: 100%;
    }

    &&& .ant-form-item {
      width: 100%;
      margin-bottom: 0;
    }

    @media screen and ${DEVICES.LAPTOP_S} {
      flex-direction: ${({ $column }) => ($column ? 'column' : 'row')};

      .ant-input {
        min-width: 0;
        width: 100%;
      }
    }
  }
`;

export const DetailsBlock = styled.div`
  position: unset;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  :last-child {
    width: 100%;
    position: relative;
    flex-direction: column;
    padding-top: 1.5rem;
    gap: 1rem;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      width: auto;
      position: relative;
      flex-direction: row;
      padding-top: 1.5rem;
      gap: 1rem;
    }
  }
`;

export const DocumentLinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTitle = styled.h2`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  color: ${COLORS.SAMBUCUS};
  margin-top: 0rem;
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const FieldsWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const TitleBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  margin-bottom: 1rem;
  &&& .ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;

export const StyledBadge = styled.div`
  display: flex;
  width: 100%;
  height: 2.875rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  background: ${COLORS.SOLITUDE_GRAY};

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 12rem;
  }
`;

export const StyledA = styled.div`
  color: ${COLORS.SOLITUDE_DARK};
  width: 100%;
  cursor: pointer;
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};

  :hover {
    color: ${COLORS.SOLITUDE_DARK};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }

  &&& .ant-form-item {
    width: 100%;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: column;
  }
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormFieldsWrapper = styled.div`
  width: 100%;
  gap: 1rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-left: 1.25rem;
`;
