// Globals
import React from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

// Components
import {
  Checkbox,
  StyledInput,
  StyledSelect,
  TextEditorWithHtml,
  Title,
} from '@/ui';
import {
  StyledFormItemWrapper,
  StyledDatePicker,
  StyledRow,
  StyledSharingUrlWrap,
} from './GeneralInfoStyles';
import {
  StyledWrapper,
  StyledFrom,
  StyledFormItem,
  StyledText,
} from '../Styles/Styles';
import { FormList, IFormInstance } from '@/components';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers
import { IFundingRoundFields } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { normalizeFundingRoundData } from '../../helpers';
import { cryptoCurrenciesList } from '../../constants';
import { getCurrentDomain } from '@/utils';

interface IGeneralInfo {
  fundingRound: IFundingRound | null;
  formInstance: IFormInstance;
  handleChange?: (value: IFundingRound, values: IFundingRound) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const GeneralInfo = ({
  formInstance,
  fundingRound,
  disabled,
  handleChange,
}: IGeneralInfo) => {
  const { t } = useTranslation(['fundingRound']);
  const validationRules = useGetFormValidationRules();

  if (!fundingRound) return <Skeleton />;

  return (
    <StyledWrapper>
      <StyledFrom
        form={formInstance}
        initialValues={normalizeFundingRoundData(fundingRound)}
        onValuesChange={handleChange}
        disabled={disabled}
      >
        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_is_internal_title')}</Title>
          <StyledFormItem
            name={IFundingRoundFields.IS_INTERNAL}
            label={t('funding_details_is_internal_label')}
            valuePropName="checked"
          >
            <Checkbox />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_elevator_title')}</Title>
          <StyledText>{t('funding_details_elevator_description')}</StyledText>
          <StyledFormItem
            name={IFundingRoundFields.ELEVATOR_PITCH}
            rules={validationRules[IFundingRoundFields.ELEVATOR_PITCH]}
            label={t('funding_details_description_title')}
          >
            <TextEditorWithHtml rows={5} />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_dates_title')}</Title>
          <StyledText>{t('funding_details_tge_date_description')}</StyledText>
          <StyledFormItem
            name={IFundingRoundFields.TGE_DATE}
            rules={validationRules[IFundingRoundFields.TGE_DATE]}
            label={t('funding_details_tge_date_title')}
          >
            <StyledDatePicker allowClear={false} />
          </StyledFormItem>
          <StyledRow>
            <StyledFormItem
              name={IFundingRoundFields.START_DATE}
              rules={validationRules[IFundingRoundFields.START_DATE]}
              label={t('funding_details_start_date_title')}
            >
              <StyledDatePicker allowClear={false} />
            </StyledFormItem>

            <StyledFormItem
              name={IFundingRoundFields.END_DATE}
              rules={validationRules[IFundingRoundFields.END_DATE]}
              label={t('funding_details_end_date_title')}
            >
              <StyledDatePicker allowClear={false} />
            </StyledFormItem>
          </StyledRow>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_target_wallet_title')}</Title>
          <StyledText>
            {t('funding_details_target_wallet_description')}
          </StyledText>
          <StyledFormItem
            name={IFundingRoundFields.WALLET_NETWORK}
            rules={validationRules[IFundingRoundFields.WALLET_NETWORK]}
            label={t('funding_details_wallet_network_label')}
          >
            <StyledInput
              disabled
              placeholder={t('funding_details_wallet_network_label')}
            />
          </StyledFormItem>
          <StyledFormItem
            name={IFundingRoundFields.WALLET_CURRENCY}
            rules={validationRules[IFundingRoundFields.WALLET_CURRENCY]}
            label={t('funding_details_wallet_currency_label')}
          >
            <StyledSelect
              placeholder={t('funding_details_wallet_currency_label')}
              options={cryptoCurrenciesList ?? []}
            />
          </StyledFormItem>
          <StyledFormItem
            name={IFundingRoundFields.WALLET_ADDRESS}
            rules={validationRules[IFundingRoundFields.WALLET_ADDRESS]}
            label={t('funding_details_wallet_address_label')}
          >
            <StyledInput
              placeholder={t('funding_details_wallet_address_label')}
            />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_sharing_url_title')}</Title>
          <StyledSharingUrlWrap>
            {getCurrentDomain()}
            {t('funding_details_project_title')}
            <StyledFormItem
              name={IFundingRoundFields.SLUG}
              rules={validationRules[IFundingRoundFields.SLUG]}
              label={t('funding_details_slug_title')}
            >
              <StyledInput placeholder={t('funding_details_slug_title')} />
            </StyledFormItem>
          </StyledSharingUrlWrap>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_benefits_title')}</Title>
          <FormList name={IFundingRoundFields.BENEFITS}>
            {() => {
              return (
                <>
                  <StyledFormItem
                    name={IFundingRoundFields.EARLY_ACCESS}
                    label={t('funding_details_early_access_label')}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </StyledFormItem>
                  <StyledFormItem
                    name={IFundingRoundFields.HIGHER_TICKET}
                    label={t('funding_details_higher_ticket_sizes_label')}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </StyledFormItem>
                  <StyledFormItem
                    name={IFundingRoundFields.NFT_ONLY}
                    label={t('funding_details_only_nft_holders_label')}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </StyledFormItem>
                </>
              );
            }}
          </FormList>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_questionnaire_link_title')}
          </Title>
          <StyledFormItem
            name={IFundingRoundFields.QUESTIONNAIRE_LINK}
            rules={validationRules[IFundingRoundFields.QUESTIONNAIRE_LINK]}
            label={t('funding_details_questionnaire_link_title')}
          >
            <StyledInput
              placeholder={t('funding_details_questionnaire_link_placeholder')}
            />
          </StyledFormItem>
        </StyledFormItemWrapper>
      </StyledFrom>
    </StyledWrapper>
  );
};

export default GeneralInfo;
