// Globals
import React, { useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { LogoIcon } from '@/icons';
import {
  showErrorToast,
  StatusChangeBlock,
  StepsProgress,
  Tabs,
  useForm,
} from '@/components';
import {
  HeaderWrapper,
  LogoWrap,
  StyledContentWrapper,
  StyledFields,
  StyledHeaderWrapper,
  StyledStatus,
} from './FundingRoundDetailsContainerStyles';
import {
  StyledButton,
  StyledButtonWrapper,
  StyledChevronIcon,
} from '../../components/Styles/Styles';
import { StyledPageWrapper, Title } from '@/ui';
import {
  FundingOverview,
  GeneralInfo,
  Presentation,
  Tokenomics,
} from '../../components';
import ParticipationListContainer from '../ParticipationListContainer/ParticipationListContainer';

// Modules
import { LogContainer, getLogs } from '@/modules/Logs';
import { getStatusesConfig } from '@/modules/SharedProfile';
import {
  fileLoadingSelector,
  uploadFundingRoundFile,
} from '@/modules/Uploaders';
import { usePermissions } from '@/modules/Permissions';
import {
  ContractInformationContainer,
  updateFundingRoundState,
} from '@/modules/FundingRounds';

// Models
import {
  FundingRoundStatuses,
  IFundingContract,
  IFundingRound,
} from '@/models/fundingRound.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Helpers
import { getFileType, getUserId, validateWalletFields } from '@/utils';
import { useStepsContext } from '../../context/StepsContext';
import { FundingSteps, IFundingRoundFields, fileFields } from '../../types';
import {
  CREATED_FUNDING_ROUND_STATUSES,
  DISABLED_FUNDING_ROUND_STATUSES,
  fundingTabsConfig,
  getFundingSteps,
  statusesConfigs,
} from '../../constants';
import { useAppDispatch, useAppState, useTabs } from '@/hooks';
import {
  getFundingDetails,
  updateFundingDetails,
  updateFundingSlug,
} from '../../feature/actionCreators';
import { fundingRoundSliceSelector } from '../../feature/selectors';
import { resetFundingRound } from '../../feature/slice';
import { getUnfilledFundingStep } from '../../helpers';
import FundingContractDTO from '../../dtos/FundingRoundDTO';

const FundingRoundDetailsContainer = () => {
  const { t } = useTranslation(['fundingRound']);
  const dispatch = useAppDispatch();
  const { tab, id } = useParams();
  const [form] = useForm();
  const { activeTab, onChange } = useTabs(
    tab ?? FundingSteps.ELEVATOR_PITCH.toString(),
  );
  const { currentStep, goNext, goPrev, setCurrenStep } = useStepsContext();
  const { fundingRound, fundingContract, isLoading } = useAppState(
    fundingRoundSliceSelector,
  );
  const fileLoading = useAppState(fileLoadingSelector);
  const steps = useMemo(() => getFundingSteps(t), [t]);
  const [permissionToEdit] = usePermissions(
    AdminActionsEnum.WRITE_FUNDING_ROUND,
  );

  const isOverviewStep = currentStep.key === FundingSteps.OVERVIEW;
  const isLegalRequirementsStep =
    currentStep.key === FundingSteps.LEGAL_REQUIREMENTS;
  const isElevatorPitchStep = currentStep.key === FundingSteps.ELEVATOR_PITCH;
  const isFundingRoundCreated = CREATED_FUNDING_ROUND_STATUSES.has(
    fundingRound?.status as FundingRoundStatuses,
  );
  const isFundingRoundDisabled = DISABLED_FUNDING_ROUND_STATUSES.has(
    fundingRound?.status as FundingRoundStatuses,
  );
  const disabled = !permissionToEdit || isFundingRoundDisabled;

  const isNavigationHidden = isFundingRoundCreated;
  const currentTab = isFundingRoundCreated
    ? parseInt(activeTab as string)
    : currentStep?.key;

  const handleGoNext = useCallback(() => {
    if (!isLegalRequirementsStep) {
      return goNext();
    }

    const unfilledStep = getUnfilledFundingStep(
      fundingRound as IFundingRound,
      fundingContract as IFundingContract,
    );

    unfilledStep && setCurrenStep(unfilledStep - 1);

    if (!unfilledStep) {
      goNext();
    }
  }, [fundingRound, currentStep, fundingContract]);

  const handleFileUpload = useCallback(async (value: File) => {
    if (!id) return;

    const cloudfrontUrl = await dispatch(
      uploadFundingRoundFile({
        value,
        fileType: getFileType(value),
        targetId: getUserId(id),
      }),
    ).unwrap();

    return cloudfrontUrl;
  }, []);

  const handleChangeStatus = useCallback(
    async (description: string, status?: string) => {
      await dispatch(
        updateFundingDetails({
          reason: description,
          status: status as FundingRoundStatuses,
          id: getUserId(id ?? ''),
        }),
      ).unwrap();
      dispatch(
        getLogs({
          entity: LogEntities.FUNDING_ROUNDS,
          id: getUserId(id ?? ''),
        }),
      );
    },
    [dispatch, fundingRound],
  );

  const handleChange = useCallback(
    async (value: IFundingRound, values: IFundingRound) => {
      const key = Object.keys(value)[0];
      const isFileField = fileFields.includes(key as IFundingRoundFields);
      const fileValue = Object.values(value)[0] as unknown as File;
      const isWalletFieldsFilled =
        !!values[IFundingRoundFields.WALLET_ADDRESS] &&
        !!values[IFundingRoundFields.WALLET_CURRENCY];

      if (isWalletFieldsFilled) {
        const isValid = await validateWalletFields(
          form,
          dispatch,
          getUserId(id ?? ''),
        );

        if (!isValid) {
          showErrorToast({
            message: t('funding_details_wallet_address_error'),
          });
          return;
        }
      }

      if (key === IFundingRoundFields.SLUG) {
        dispatch(
          updateFundingSlug({
            id: getUserId(id ?? ''),
            slug: value.slug ?? '',
          }),
        );
        return;
      }

      if (isFileField && id && fileValue) {
        const handledValue =
          fileValue &&
          (await dispatch(
            uploadFundingRoundFile({
              fileType: getFileType(fileValue),
              value: fileValue,
              targetId: getUserId(id ?? ''),
            }),
          ).unwrap());

        dispatch(
          updateFundingDetails({
            [key]: handledValue,
            id: getUserId(id ?? ''),
          }),
        );
        form.setFieldValue([key], handledValue);
      } else {
        const dto = new FundingContractDTO(
          values,
          fundingRound as IFundingRound,
        );
        dispatch(updateFundingRoundState({ [key]: fileValue }));
        await dispatch(
          updateFundingDetails({ ...dto, id: getUserId(id ?? '') }),
        ).unwrap();
      }

      dispatch(
        getLogs({
          entity: LogEntities.FUNDING_ROUNDS,
          id: getUserId(id ?? ''),
        }),
      );
    },
    [dispatch, id, currentStep, fundingRound],
  );

  const debouncedHandleChange = debounce(handleChange, 500);

  const getSectionContent = (step: FundingSteps) => {
    switch (step) {
      case FundingSteps.ELEVATOR_PITCH:
        return (
          <GeneralInfo
            formInstance={form}
            disabled={disabled}
            fundingRound={fundingRound}
            isLoading={isLoading}
            handleChange={debouncedHandleChange}
          />
        );
      case FundingSteps.PRESENTATION:
        return (
          <Presentation
            formInstance={form}
            disabled={disabled}
            fundingRound={fundingRound}
            isLoading={isLoading}
            handleChange={debouncedHandleChange}
            handleFileUpload={handleFileUpload}
            fileLoading={fileLoading}
          />
        );
      case FundingSteps.TOKENOMICS:
        return (
          <Tokenomics
            formInstance={form}
            disabled={disabled}
            fundingRound={fundingRound}
            isLoading={isLoading}
            handleChange={debouncedHandleChange}
          />
        );
      case FundingSteps.LEGAL_REQUIREMENTS:
        return <ContractInformationContainer isDisabled={disabled} />;
      case FundingSteps.PARTICIPATION:
        return <ParticipationListContainer id={id} />;
      case FundingSteps.OVERVIEW:
        return <FundingOverview fundingRound={fundingRound as IFundingRound} />;
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getFundingDetails(getUserId(id)));
    }
  }, [id, fundingRound?.status]);

  useEffect(
    () => (): void => {
      dispatch(resetFundingRound());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <StyledHeaderWrapper>
        <Title $type="h3">{fundingRound?.projectTitle ?? ''}</Title>
        <StyledStatus>
          <Title $type="h2">{t('funding_rounds_status')}</Title>
          <StatusChangeBlock
            status={fundingRound?.status}
            isEditable={true}
            handleRequest={handleChangeStatus}
            statusesConfigs={getStatusesConfig(
              fundingRound?.status ?? '',
              statusesConfigs,
            )}
            withStatus
          />
        </StyledStatus>
      </StyledHeaderWrapper>

      <StyledContentWrapper>
        <StyledFields>
          {!isFundingRoundCreated ? (
            <>
              <HeaderWrapper>
                <LogoWrap>
                  <LogoIcon />
                </LogoWrap>
                {t('funding_details_creation_title')}
              </HeaderWrapper>

              <StepsProgress
                onChange={setCurrenStep}
                items={steps}
                current={currentStep.key - 1}
                labelPlacement="vertical"
              />
            </>
          ) : (
            <Tabs
              sections={fundingTabsConfig}
              activeTab={activeTab ?? ''}
              onChange={onChange}
            />
          )}

          {getSectionContent(currentTab)}

          {!isNavigationHidden && (
            <StyledButtonWrapper $isFlexEnd={isElevatorPitchStep}>
              {!isElevatorPitchStep && (
                <StyledButton type="ghost" onClick={goPrev}>
                  <StyledChevronIcon $isColored rotate={180} />
                  {t('funding_rounds_back')}
                </StyledButton>
              )}
              {!isOverviewStep && (
                <StyledButton $isColored type="primary" onClick={handleGoNext}>
                  {t('funding_rounds_proceed')}
                  <StyledChevronIcon rotate={0} />
                </StyledButton>
              )}
            </StyledButtonWrapper>
          )}
        </StyledFields>
      </StyledContentWrapper>
      {!isOverviewStep && (
        <LogContainer
          entity={LogEntities.FUNDING_ROUNDS}
          id={fundingRound?.id}
        />
      )}
    </StyledPageWrapper>
  );
};

export default FundingRoundDetailsContainer;
